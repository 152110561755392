import { NotFoundErrorView } from './pages/NotFound';

// styles
import './Error.scss';

export function ErrorPage() {
    return (
        <div className="errorPage">
            <NotFoundErrorView />
        </div>
    );
}
