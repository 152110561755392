import { Typography } from '@mui/material';

export function NotFoundErrorView() {
    return (
        <div className="notFoundError">
            <Typography variant="h1" color="white">
                404
            </Typography>
            <Typography variant="body1" color="white">
                Sorry, we couldn't find the page you are looking for.
            </Typography>
        </div>
    );
}
