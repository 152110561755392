import React from 'react';
import { Button } from '@mui/material';
import { CommentOutlined } from '@mui/icons-material';

export type CommentButtonProps = {
    count?: number;
    onClick?: () => void;
};

function CommentButton(props: CommentButtonProps) {
    const { count, onClick } = props;

    const iconOnly = count == null || count <= 0;

    const Icon = () => <CommentOutlined fontSize="small" />;

    return (
        <Button
            className="commentButton"
            startIcon={!iconOnly ? <Icon /> : undefined}
            color="neutral"
            onClick={onClick}
        >
            {iconOnly ? <Icon /> : count}
        </Button>
    );
}
export default React.memo(CommentButton);
