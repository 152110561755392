import React from 'react';
import { PlayArrow } from '@mui/icons-material';

// types
import type { Media } from 'types/core';
import { MediaType } from 'types/enums';

// helper
import MediaHelper from 'app/helper/media';

// styles
import './MediaThumbnail.scss';

export type MediaThumbnailProps = {
    media: Media;
    className?: string;
    onClick?: () => void;
};

function MediaThumbnail(props: MediaThumbnailProps) {
    const { media, className = '', onClick } = props;

    // media
    const { uri } = media;
    const mediaType = MediaHelper.getMediaType(media);
    const thumbnailUri = media.thumbnailUri ?? (mediaType === MediaType.Image ? uri : null);

    // video
    if (mediaType === MediaType.Video) {
        return (
            <div className={`${className} mediaThumbnail --video`} onClick={onClick}>
                {thumbnailUri && <img src={thumbnailUri} alt="" />}
                <div className="overlay">
                    <PlayArrow />
                </div>
            </div>
        );
    }

    // default (image)
    return <img className={`${className} mediaThumbnail --image`} src={thumbnailUri || uri} alt="" onClick={onClick} />;
}
export default React.memo(MediaThumbnail);
