export enum SearchType {
    Place = 1,
    Account,
    Hashtag,
  }
  
  export enum AudienceType {
    Public = 1,
    Private,
    Followers,
    Circles,
  }
  
  export enum PostType {
    Default = 1,
    PlaceTip = 2,
    PlaceRating = 3,
    PlacePhoto = 4,
    Journey = 5,
    Moment = 7,
    Memory = 11,
  }
  
  export enum DayOfWeek {
    Monday = 1,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday = 7,
  }
  
  export enum WeekOfMonth {
    FirstWeek = 1,
    SecondWeek,
    ThirdWeek,
    ForthWeek,
    FifthWeek,
    FinalWeek = -1,
  }
  
  export enum MonthOfYear {
    January = 1,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December,
  }
  
  export enum OpeningDayStatus {
    Open,
    Closed,
    Open24Hours,
  }
  
  export enum ReactionType {
    Like = 1,
    Love,
    Surprise,
    Laugh,
    Cry,
  }
  
  /** Type of notification, grouped according to its included data, action or navigation defined. */
  export enum NotificationType {
    Default = 0,
    PostUpdate,
    CommentUpdate,
    ReplyUpdate,
    PlaceTipUpdate,
    PlaceRatingUpdate,
    PlaceMediaUpdate,
    FollowRequest,
    FollowUpdate,
    LoginUpdate,
    KingUpdate,
    ContributionUpdate,
    JourneyUpdate,
    JourneyPostUpdate,
    JourneyMemory,
    ChatNewMessage,
    ChatMessageUpdate,
  }
  
  export enum UserFollowStatus {
    Unknown = 0,
    Following = 1,
    Requested,
    NotFollow,
    /** Rejected follow request. */
    Rejected,
  }
  
  export enum RewardType {
    General = 0,
    King = 1,
    Badge,
  }
  
  export enum DeviceOSType {
    Android,
    IOS,
    Unknown,
  }
  
  export enum MessagingDeviceType {
    Unknown = 0,
    /** Android device with Huawei Mobile Service installed. (e.g. Huawei device) */
    Android_HMS,
    /** Android device with Google Mobile Service (Play Service) installed. */
    Android_GMS,
    IOS,
  }
  
  export enum JourneyViewType {
    Unknown = 0,
    Owner,
    Collaborator,
    Follower,
    Visitor,
  }
  
  export enum JourneyRepeatType {
    Daily = 1,
    Weekdays,
    Weekends,
    Custom,
  }
  
  export enum JourneyRepeatFrequencyType {
    Day = 1,
    Week,
    Month,
    Year,
  }
  
  /**
   * Yearly Repeat Type to extend the enum value of `JourneyRepeatFrequencyType.Year`.
   *
   * The value of `4`, `5` is to map with value supported by `ApiDataType.JourneyScheduler.repeatValue` (e.g. 1,2,3,4,5)
   */
  export enum JourneyYearRepeatType {
    ByDate = 4,
    ByDay = 5,
  }
  
  export enum MediaType {
    Unknown = 0,
    Image,
    Video,
  }
  