import React, { useCallback, useMemo } from 'react';
import { Card, CardActions, CardContent, CardHeader, Skeleton } from '@mui/material';

// types
import { Post } from 'types/core';
import { PostType, ReactionType } from 'types/enums';

// helper
import CommonHelper from 'app/helper/common';
import MediaHelper from 'app/helper/media';

// components
import MediaContent from 'components/MediaContent/MediaContent';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import PlaceTag from 'components/PlaceTag/PlaceTag';
import ContentText from 'components/ContentText/ContentText';
import LikeButton from 'components/Button/LikeButton/LikeButton';
import CommentButton from 'components/Button/CommentButton/CommentButton';
import QuoteView from 'components/QuoteView/QuoteView';

export type DefaultPostComponentProps = {
    post: Post | null | undefined;
    isLoading?: boolean;
};

function DefaultPostComponent(props: DefaultPostComponentProps) {
    const { post, isLoading } = props;

    // post
    const { type, user, postMessage, place, tags, reactions, commentCount, mediaCollection } = post || {};

    // user
    const { name } = user || {};

    // media
    const hasMedia = mediaCollection && mediaCollection.length > 0;
    const mediaCaption = useMemo(() => MediaHelper.getMediaCollectionCaption(mediaCollection), [mediaCollection]);

    // postType
    const isCheckIn = type === PostType.Default && place != null && !postMessage && !hasMedia;
    const isPlaceReview = type && [PostType.PlaceTip, PostType.PlaceRating, PostType.PlacePhoto].includes(type);

    // reaction
    const reaction = reactions?.find((r) => r.type === ReactionType.Like);
    const reactionCount = reaction?.count ?? 0;

    // action
    const next = useCallback(() => {
        if (post != null) CommonHelper.goToApp(`post/${post.postId}`);
    }, [post]);

    return (
        <Card className="post">
            {post != null ? (
                <>
                    <CardHeader
                        avatar={<UserAvatar user={user} onClick={next} />}
                        title={
                            <span>
                                <strong className="cursor-pointer" onClick={next}>
                                    {name}
                                </strong>
                                {(() => {
                                    // JourneyPost
                                    if (post.type === PostType.Journey) {
                                        return (
                                            <>
                                                <span> added {mediaCaption ? mediaCaption : 'a post'} to journey </span>
                                                <strong className="cursor-pointer" onClick={next}>
                                                    {post.journey.name}
                                                </strong>
                                            </>
                                        );
                                    }

                                    // PlaceReviewPost
                                    let caption = '';
                                    switch (post.type) {
                                        case PostType.PlaceTip:
                                            caption = ` added a tip to place `;
                                            break;
                                        case PostType.PlaceRating:
                                            caption = ' added a rating to place ';
                                            break;
                                        case PostType.PlacePhoto:
                                            caption = ` added ${mediaCaption} to place `;
                                            break;
                                    }
                                    if (!!caption && post.place != null) {
                                        return (
                                            <>
                                                {caption}
                                                <strong className="cursor-pointer" onClick={next}>
                                                    {post.place.name}
                                                </strong>
                                            </>
                                        );
                                    }
                                })()}
                            </span>
                        }
                        subheader={CommonHelper.momentFromNow(post.createdOn)}
                    />
                    {isCheckIn ? (
                        <PlaceTag place={place} onClick={next} />
                    ) : (
                        <>
                            {!!postMessage &&
                                (() => {
                                    const MessageContent = () => (
                                        <ContentText text={postMessage} includes={{ users: tags }} onClick={next} />
                                    );
                                    return (
                                        <CardContent>
                                            {isPlaceReview ? (
                                                <QuoteView>
                                                    <MessageContent />
                                                </QuoteView>
                                            ) : (
                                                <MessageContent />
                                            )}
                                        </CardContent>
                                    );
                                })()}
                            {hasMedia && <MediaContent mediaCollection={mediaCollection} />}
                            {!isPlaceReview && place != null && <PlaceTag place={place} onClick={next} />}
                        </>
                    )}
                    <CardActions className="actions">
                        {(() => {
                            switch (type) {
                                case PostType.Memory:
                                    break;
                                default: {
                                    return (
                                        <>
                                            <LikeButton count={reactionCount} onClick={next} />
                                            <CommentButton count={commentCount} onClick={next} />
                                        </>
                                    );
                                }
                            }
                        })()}
                    </CardActions>
                </>
            ) : (
                isLoading && (
                    <>
                        <CardHeader
                            avatar={<UserAvatar user={null} isLoading />}
                            title={<Skeleton width={120} />}
                            subheader={<Skeleton width={80} />}
                        />
                        <CardContent>
                            <Skeleton width={250} />
                        </CardContent>
                    </>
                )
            )}
        </Card>
    );
}
export default React.memo(DefaultPostComponent);
