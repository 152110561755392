import React, { useMemo, useState } from 'react';
import { Dialog, ImageList, ImageListItem, imageListItemClasses } from '@mui/material';

// type
import type { Media } from 'types/core';

// components
import MediaThumbnail from 'components/MediaThumbnail/MediaThumbnail';
import MediaGallery, { MediaGalleryItem } from 'components/MediaGallery/MediaGallery';

// styles
import './MediaContent.scss';

export type MediaContentProps = {
    mediaCollection: Media[];
};

function MediaContent(props: MediaContentProps) {
    const { mediaCollection } = props;

    const galleryItems = useMemo<MediaGalleryItem[]>(() => {
        return mediaCollection.map((media) => ({ original: media.uri }));
    }, [mediaCollection]);

    // grid
    const mediaCount = mediaCollection.length;
    const numOfColumn = [2, 4].includes(mediaCount) ? 2 : 3;

    // gallery
    const [galleryIndex, setGalleryIndex] = useState(-1);
    const isOpenGallery = galleryIndex >= 0;

    return (
        <>
            {mediaCount === 1 ? (
                <CoverMedia media={mediaCollection[0]} onClick={() => setGalleryIndex(0)} />
            ) : (
                <GridMedia media={mediaCollection} column={numOfColumn} onClick={setGalleryIndex} />
            )}
            <Dialog open={isOpenGallery} fullWidth maxWidth={false} onClose={() => setGalleryIndex(-1)}>
                <MediaGallery items={galleryItems} startIndex={galleryIndex} />
            </Dialog>
        </>
    );
}
export default React.memo(MediaContent);

// CoverMedia
type CoverMediaProps = {
    media: Media;
    onClick?: () => void;
};
function CoverMedia(props: CoverMediaProps) {
    const { media, onClick } = props;

    return (
        <div className="mediaContent --cover" onClick={onClick}>
            <MediaThumbnail media={media} />
        </div>
    );
}

// GridMedia
type GridMediaProps = {
    media: Media[];
    column?: number;
    maxRow?: number;
    onClick?: (index: number) => void;
};
function GridMedia(props: GridMediaProps) {
    const { media, column = 3, maxRow = 3, onClick } = props;

    // list
    const maxCount = column * maxRow;
    const overCount = media.length - maxCount;

    return (
        <ImageList className="mediaContent --grid" cols={column} gap={2}>
            {media.slice(0, maxCount).map((content, index) => (
                <ImageListItem key={index} className="cursor-pointer" onClick={() => onClick?.(index)}>
                    <MediaThumbnail className={imageListItemClasses.img} media={content} />
                    {index === maxCount - 1 && overCount > 0 && (
                        <div className="overCount">
                            <span className="overCountText">+{overCount}</span>
                        </div>
                    )}
                </ImageListItem>
            ))}
        </ImageList>
    );
}
