import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

// slices
import reducer from './slices';
import api from './slices/api';

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    // api middleware enabled caching, invalidation, polling and other useful features of 'rtk-query'
    return getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(api.middleware);
  },
});
export default store;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
