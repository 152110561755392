// types
import type { Post } from 'types/core';
import { PostType } from 'types/enums';

// components
import DefaultPost from './components/DefaultPost';
import MomentPostComponent from './components/MomentPost';

// styles
import './Post.scss';

export type PostComponentProps = {
    post: Post | null | undefined;
    isLoading?: boolean;
};

function PostComponent(props: PostComponentProps) {
    const { post, isLoading = false } = props;

    // custom
    if (post != null) {
        switch (post.type) {
            case PostType.Moment:
                return <MomentPostComponent post={post} />;
        }
    }

    // default
    return <DefaultPost post={post} isLoading={isLoading} />;
}
export default PostComponent;
