import { combineReducers } from 'redux';

// slices
import api from './api';

const reducer = combineReducers({
    [api.reducerPath]: api.reducer,
});
export default reducer;

export type StoreState = ReturnType<typeof reducer>;
