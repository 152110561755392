import React from 'react';
import { Typography } from '@mui/material';

// types
import { Place } from 'types/core';

// assets
import yesslyIcon from 'assets/icon-xs.png';

// styles
import './PlaceTag.scss';

export type PlaceTagProps = {
    place: Place;
    small?: boolean;
    onClick?: () => void;
};

function PlaceTag(props: PlaceTagProps) {
    const { place, small, onClick } = props;

    // place
    const { name, address } = place;

    // ui
    const clickable = onClick != null;

    return (
        <div className={`placeTag ${small ? '--small' : ''} ${clickable ? 'cursor-pointer' : ''}`} onClick={onClick}>
            <div className="title">
                <img className="tagIcon" src={yesslyIcon} alt="yessly icon" />
                <Typography variant="body1" fontWeight={!small ? 'bold' : undefined}>
                    {name.trim()}
                </Typography>
            </div>
            {!small && address && (
                <div className="subtitle">
                    <Typography variant="body2" textAlign="center" color="GrayText">
                        {address.trim()}
                    </Typography>
                </div>
            )}
        </div>
    );
}
export default React.memo(PlaceTag);
