import React from 'react';
import { Avatar, Skeleton } from '@mui/material';
import { Person } from '@mui/icons-material';

// types
import type { User } from 'types/core';

// helper
import Helper from 'app/helper/common';

export type UserAvatarProps = {
    user: User | null | undefined;
    isLoading?: boolean;
    onClick?: (user: User) => void;
};

function UserAvatar(props: UserAvatarProps) {
    const { user, isLoading = false, onClick } = props;

    // invalid user
    if (user == null)
        return isLoading ? (
            <Skeleton variant="circular" width={40} height={40} />
        ) : (
            <Avatar>
                <Person />
            </Avatar>
        );

    const clickable = onClick != null;
    const onClickUser = () => onClick?.(user);

    // with image
    if ('avatarUrl' in user && user.avatarUrl)
        return <Avatar src={user.avatarUrl} className={clickable ? 'cursor-pointer' : ''} onClick={onClickUser} />;

    // with initial from name
    const initial = Helper.getInitial(user.name || '') || '#';
    return (
        <Avatar className={clickable ? 'cursor-pointer' : ''} onClick={onClickUser}>
            {initial.toUpperCase()}
        </Avatar>
    );
}
export default React.memo(UserAvatar);
