import { $enum } from 'ts-enum-util';
import { random } from 'lodash';
import mime from 'mime';
import moment from 'moment';

// types
import { ApiDataType } from '../types';
import type { Journey, JourneyDuration, JourneyRepeat, Place, PlaceCategory } from 'types/core';
import type { User, Media, Reaction, Moment, Post, MomentPost, GeneralPost, JourneyPost } from 'types/core';
import type { Audience, AudienceCircle, Circle } from 'types/core';
import { JourneyViewType, JourneyRepeatType, JourneyRepeatFrequencyType, JourneyYearRepeatType } from 'types/enums';
import { PostType, AudienceType, UserFollowStatus, DayOfWeek, MonthOfYear } from 'types/enums';
import { AnonymousUser, BrokenMedia, UnknownPlace } from 'types/constant';

// Parser

export function parseUser(user: ApiDataType.User): User {
    const { userName, fullName, profileUrl, followerStatus, followingStatus, ...rest } = user;
    return {
        ...rest,
        username: userName,
        name: fullName || userName,
        avatarUrl: profileUrl,
        followStatus: parseUserFollowStatus(followerStatus),
        followerStatus: parseUserFollowStatus(followingStatus),
    };
}

export function parsePost(post: ApiDataType.Post): Post {
    const { typeId, postMessage, user, place, tags, reaction, media, checkInDate, createdOn, privacy, ...partial } =
        post;
    const { journeyId, journeyName, journeyViewType, viewCount, likeCount, ...rest } = partial;

    const type = $enum(PostType).asValueOrDefault(typeId, PostType.Default);

    // general (base type of all kind of post)
    const generalPost: GeneralPost = {
        ...rest,
        postMessage: postMessage ? postMessage : undefined,
        user: parseUser(user),
        place: place != null ? parsePlace(place) : undefined,
        tags: tags?.map((taggedUser) => parseUser(taggedUser)) ?? undefined,
        audience: parseAudience(privacy),
        reactions: reaction?.map((reaction) => parseReaction(reaction)) ?? [],
        mediaCollection: media?.map((m) => parseMedia(m)) ?? undefined,
        checkInDate: checkInDate ?? createdOn,
        createdOn,
    };

    switch (type) {
        case PostType.Journey: {
            if (journeyId != null && journeyName != null) {
                const journey: JourneyPost['journey'] = {
                    id: journeyId,
                    name: journeyName,
                    viewType: $enum(JourneyViewType).asValueOrDefault(journeyViewType, JourneyViewType.Unknown),
                };
                return { type, ...generalPost, journey };
            }
            break;
        }
        case PostType.Moment: {
            if (viewCount != null && likeCount != null) {
                const moment: MomentPost['moment'] = { viewCount, likeCount };
                return { type, ...generalPost, moment };
            }
            break;
        }
        case PostType.PlaceTip:
        case PostType.PlaceRating:
        case PostType.PlacePhoto:
            return { type, ...generalPost, place: generalPost.place ?? UnknownPlace };
        default:
            return { type, ...generalPost };
    }

    // fallback as default
    return { type: PostType.Default, ...generalPost };
}

export function parsePlace(place: ApiDataType.Place): Place {
    const { osmId, placeName, lat, lon, fullAddress, ...rest } = place;
    return {
        ...rest,
        id: osmId,
        name: placeName,
        address: fullAddress,
        category: parsePlaceCategoryByPlace(place),
    };
}

export function parsePlaceCategoryByPlace(
    place: ApiDataType.Place | ApiDataType.PlaceWithInfo,
): PlaceCategory | undefined {
    const { categoryId: id, category: value } = place;
    if (id && value) return { id, value };
}

export function parsePlaceByFootprint(footprint: ApiDataType.Footprint): Place | undefined {
    const { osmId, displayName = '' } = footprint;

    if (osmId) return { id: osmId, name: displayName };
}

export function parseCircle(circle: ApiDataType.Circle): Circle {
    const { circleId, circleName, ...rest } = circle;
    return {
        ...rest,
        id: circleId,
        name: circleName,
    };
}

export function parseAudience(audience: ApiDataType.Audience): Audience {
    const { permissionId, circles } = audience || {};
    const audienceType = $enum(AudienceType).asValueOrDefault(permissionId, AudienceType.Public);
    return {
        type: audienceType,
        circles: circles != null ? circles.map((circle) => parseAudienceCircle(circle)) : undefined,
    };
}

export function parseAudienceCircle(circle: ApiDataType.AudienceCircle): AudienceCircle {
    const { circleId: id, circleName: name } = circle;
    return { id, name };
}

export function parseReaction(reaction: ApiDataType.Reaction): Reaction {
    const { reactionType, users, ...rest } = reaction;
    return {
        ...rest,
        type: reactionType,
        users: users.map((user) => parseUser(user)),
    };
}

export function parseMedia(media: ApiDataType.Media): Media {
    const { fileUrl, fileExtension, mime: fileType, ...rest } = media;

    // id
    const id = random(10000).toString();

    // type
    const type = fileType || fileExtension ? mime.getType(fileExtension) ?? undefined : undefined;

    return {
        ...rest,
        id,
        uri: fileUrl,
        type,
    };
}

export function parseUserFollowStatus(followerStatus: number): UserFollowStatus {
    return $enum(UserFollowStatus).asValueOrDefault(followerStatus, UserFollowStatus.Unknown);
}

export function parseJourney(journey: ApiDataType.Journey): Journey {
    const { journeyId, media, defaultHashTag, privacy, viewType, owner, collaborators, report, ...rest } = journey;

    return {
        ...rest,
        id: journeyId,
        coverImage: media != null && media.length > 0 ? parseMedia(media[0]) : undefined,
        hashtags: defaultHashTag ?? [],
        audience: parseAudience(privacy),
        viewType: $enum(JourneyViewType).asValueOrDefault(viewType, JourneyViewType.Unknown),
        owner: owner ? parseUser(owner) : undefined,
        collaborators: collaborators?.map((user) => parseUser(user)) ?? undefined,
        duration: parseJourneyDuration(journey),
        repeat: parseJourneyRepeat(journey),
        reminderTime: parseJourneyReminderTime(journey),
        stats: report,
    };
}

export function parseJourneyRepeat(journey: Pick<ApiDataType.Journey, 'setting'>): JourneyRepeat | undefined {
    const { reminder, scheduler } = journey.setting || {};

    if (reminder != null && scheduler != null) {
        const { repeatType } = scheduler;
        const type = $enum(JourneyRepeatType).asValueOrDefault(repeatType, JourneyRepeatType.Daily);
        return {
            type,
            customFrequency: (() => {
                if (type === JourneyRepeatType.Custom) {
                    const { repeatValue, repeatValueType, customRepeatValue } = scheduler;

                    // frequencyType mapping
                    let frequencyType: JourneyRepeatFrequencyType;
                    let yearRepeatType: JourneyYearRepeatType | undefined;
                    try {
                        if ($enum(JourneyYearRepeatType).isValue(repeatValue)) {
                            frequencyType = JourneyRepeatFrequencyType.Year;
                            yearRepeatType = $enum(JourneyYearRepeatType).asValueOrThrow(repeatValue);
                        } else {
                            frequencyType = $enum(JourneyRepeatFrequencyType).asValueOrDefault(
                                repeatValue,
                                JourneyRepeatFrequencyType.Day,
                            );
                        }
                    } catch {
                        frequencyType = JourneyRepeatFrequencyType.Day;
                    }

                    try {
                        return {
                            type: frequencyType,
                            value: repeatValueType ?? 1,
                            ...(() => {
                                if (customRepeatValue != null) {
                                    switch (frequencyType) {
                                        case JourneyRepeatFrequencyType.Week: {
                                            const weekDays = customRepeatValue.reduce<DayOfWeek[]>((prev, value) => {
                                                try {
                                                    const dayOfWeek = $enum(DayOfWeek).asValueOrThrow(value);
                                                    return !prev.includes(dayOfWeek) ? [...prev, dayOfWeek] : prev;
                                                } catch {
                                                    return prev;
                                                }
                                            }, []);
                                            if (weekDays.length <= 0) throw Error('Invalid `customRepeatValue` value.');
                                            return { weekDays };
                                        }
                                        case JourneyRepeatFrequencyType.Month: {
                                            const monthDays = customRepeatValue.reduce<number[]>((prev, value) => {
                                                return !prev.includes(value) ? [...prev, value] : prev;
                                            }, []);
                                            if (monthDays.length <= 0)
                                                throw Error('Invalid `customRepeatValue` value.');
                                            return { monthDays };
                                        }
                                        case JourneyRepeatFrequencyType.Year: {
                                            if (yearRepeatType != null) {
                                                return {
                                                    yearRepeat: {
                                                        type: yearRepeatType,
                                                        ...(() => {
                                                            switch (yearRepeatType) {
                                                                case JourneyYearRepeatType.ByDate:
                                                                    if (customRepeatValue.length >= 2) {
                                                                        const [monthValue, dayOfMonth = 0] =
                                                                            customRepeatValue;
                                                                        const month =
                                                                            $enum(MonthOfYear).asValueOrThrow(
                                                                                monthValue,
                                                                            );
                                                                        return { month, dayOfMonth };
                                                                    }
                                                                    break;
                                                                case JourneyYearRepeatType.ByDay:
                                                                    if (customRepeatValue.length >= 3) {
                                                                        const [dayOfWeek = 0, week = 0, monthValue] =
                                                                            customRepeatValue;
                                                                        const month =
                                                                            $enum(MonthOfYear).asValueOrThrow(
                                                                                monthValue,
                                                                            );
                                                                        return { dayOfWeek, week, month };
                                                                    }
                                                                    break;
                                                            }
                                                            throw Error('Invalid `yearRepeatType` value.');
                                                        })(),
                                                    },
                                                };
                                            }
                                        }
                                    }
                                }
                            })(),
                        };
                    } catch {
                        return { type: JourneyRepeatFrequencyType.Day, value: 1 };
                    }
                }
            })(),
        };
    }
}

export function parseJourneyDuration(journey: Pick<ApiDataType.Journey, 'setting'>): JourneyDuration {
    const { reminder } = journey.setting || {};
    return {
        startDate: reminder?.startDate ?? new Date(),
        endDate: reminder?.endDate,
    };
}

export function parseJourneyReminderTime(journey: Pick<ApiDataType.Journey, 'setting'>): Date | undefined {
    const reminder = journey.setting?.reminder;
    if (reminder != null) {
        const { isOn, reminderValue } = reminder;
        if (isOn && reminderValue != null) {
            return moment().startOf('day').add(reminderValue, 'minutes').toDate();
        }
    }
}

export function parseMoment(moment: ApiDataType.Moment): Moment {
    const { media, owner, place, taggedUsers, audience, setting, ...rest } = moment;

    return {
        ...rest,
        media: media != null && media.length > 0 ? parseMedia(media[0]) : BrokenMedia,
        owner: owner != null ? parseUser(owner) : AnonymousUser,
        place: place != null ? parsePlace(place) : undefined,
        taggedUsers: taggedUsers != null ? taggedUsers.map((user) => parseUser(user)) : undefined,
        audience: audience != null ? parseAudience(audience) : undefined,
        settings: setting,
    };
}
