import React, { useCallback } from 'react';
import { AccountCircle, Place } from '@mui/icons-material';
import { Card, CardActions, CardHeader, Chip } from '@mui/material';

// types
import type { MomentPost } from 'types/core';
import { BrokenMedia } from 'types/constant';

// helper
import CommonHelper from 'app/helper/common';

// components
import Media from 'components/Media/Media';
import UserAvatar from 'components/UserAvatar';

export type MomentPostComponentProps = {
    post: MomentPost;
};

function MomentPostComponent(props: MomentPostComponentProps) {
    const { post } = props;

    // post
    const momentId = post.postId;
    const { place, user, tags } = post;
    const hasTaggedUsers = tags && tags.length > 0;

    // user
    const { name } = user;

    // media
    const media = post.mediaCollection?.[0] || BrokenMedia;

    // action
    const next = useCallback(() => {
        CommonHelper.goToApp(`post/${momentId}`);
    }, [momentId]);

    return (
        <Card className="post --moment">
            <div className="mediaView">
                <Media media={media} />
                <CardHeader
                    className="header"
                    avatar={<UserAvatar user={user} onClick={next} />}
                    title={
                        <span className="cursor-pointer" onClick={next}>
                            {name}
                        </span>
                    }
                    subheader={CommonHelper.momentFromNow(post.createdOn)}
                />
                <div className="tags">
                    {place != null && <Chip icon={<Place />} label={place.name} onClick={next} />}
                </div>
            </div>
            {hasTaggedUsers && (
                <CardActions className="actions">
                    <AccountCircle className="cursor-pointer" onClick={next} />
                </CardActions>
            )}
        </Card>
    );
}
export default React.memo(MomentPostComponent);
