import { useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';

// helper
import CommonHelper from 'app/helper/common';

// styles
import './Landing.scss';

export function LandingPage() {
    const [isOpenedApp, setOpenedApp] = useState(false);

    const redirect = useCallback(() => {
        CommonHelper.goToApp('', () => setOpenedApp(true));
    }, []);
    useEffect(() => {
        setTimeout(redirect, 1000);
    }, [redirect]);

    return (
        <div className="landingPage">
            {isOpenedApp ? (
                <Button variant="contained" color="onPrimary" onClick={redirect}>
                    Open App
                </Button>
            ) : (
                <>
                    <Typography className="content" color="white">
                        Hold on tight! We're taking you there!
                    </Typography>
                    <CircularProgress sx={{ color: 'white' }} />
                </>
            )}
        </div>
    );
}
