import api from '.';

// types
import type { Post } from 'types/core';
import type { ApiResponse, ApiRequestType, ApiDataType } from './types';

// helper
import * as Helper from './utils/helper';

const PostApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getPostByLink: builder.query<ApiResponse<'post', Post>, ApiRequestType.GetPostByLinkVars>({
            query: (body) => ({ url: 'Post/GetPostByLink', method: 'POST', body }),
            transformResponse: (response: ApiResponse<'data', ApiDataType.Post[]>) => {
                const post = response.data?.find(() => true); // get the first one
                return { ...response, post: post ? Helper.parsePost(post) : null };
            },
            extraOptions: { errorHandled: true },
        }),
    }),
    overrideExisting: false,
});
export default PostApi;
