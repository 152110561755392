import _ from 'lodash';
import moment from 'moment';

const CommonHelper = {
    goToApp(path: string = '', onSuccess?: () => void) {
        globalThis.window.location.replace(`${process.env.REACT_APP_APP_SCHEME}://${_.trimStart(path, '/')}`);

        // fallback
        setTimeout(() => {
            if (globalThis.document.hidden) onSuccess?.();
            else globalThis.window.location.replace('https://www.yessly.app');
        }, 1000);
    },
    momentFromNow(date: Date): string {
        const thisMoment = moment();
        const thatMoment = moment.parseZone(date);

        const secondsFromNow = thisMoment.diff(thatMoment, 'seconds');
        if (secondsFromNow < 60) return 'a few seconds ago';

        const minutesFromNow = thisMoment.diff(thatMoment, 'minutes');
        if (minutesFromNow < 60) return `${minutesFromNow > 1 ? `${minutesFromNow} minutes` : 'a minute'} ago`;

        const hoursFromNow = thisMoment.diff(thatMoment, 'hours');
        if (hoursFromNow < 24) return `${hoursFromNow > 1 ? `${hoursFromNow} hours` : 'an hour'} ago`;

        if (hoursFromNow < 36) return 'a day ago';
        const daysFromNow = thisMoment.diff(thatMoment, 'days');
        return daysFromNow < 7 ? thatMoment.fromNow() : thatMoment.format('DD MMM YYYY');
    },
    /**
     * Get single or double initial characters from provided text.
     *
     * *Use Case:*
     *
     * - Get initial `HW` from text `Hello World`
     * - Get initial `F` from text `Foo`
     */
    getInitial(text: string): string {
        return (text || '').match(/\b\w/g)?.slice(0, 2).join('') || '';
    },
};
export default CommonHelper;
