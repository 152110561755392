import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment';

// types
import { ApiResponse, ApiResponseStatusCode } from '../types';

const VALID_HTTP_METHODS = ['CONNECT', 'DELETE', 'GET', 'HEAD', 'OPTIONS', 'PATCH', 'POST', 'PUT', 'TRACE'];

type ExtraOptions = {
    /** Prevents default error handling of showing error alerts. Default is `false`. */
    errorHandled?: boolean;
};
type CoreBaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, ExtraOptions, FetchBaseQueryMeta>;

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const simpleBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
        // headers
        headers.set('timezone', moment().utcOffset().toString());

        return headers;
    },
});

export const baseQuery: CoreBaseQuery = async (args, api, extraOptions = {}) => {
    const method = typeof args === 'string' ? 'GET' : args.method || 'GET';
    const { errorHandled = false } = extraOptions;

    // validate HTTP method
    if (!VALID_HTTP_METHODS.includes(method)) {
        return { error: { status: 'FETCH_ERROR', error: 'Invalid HTTP method' } };
    }

    // HTTP request
    let result = await simpleBaseQuery(args, api, extraOptions);
    const { error } = result;
    const data = result.data as ApiResponse;

    // network error
    if (error) {
        const message =
            '[FetchBaseQueryError] ' +
            (typeof error.status === 'number' ? error.status.toString() : `${error.status}. ${error.error}`);
        enqueueSnackbar(message, { variant: 'error' });
        return result;
    }

    // error handling
    if (!errorHandled && data) {
        const { responseStatus, responseMessage } = data;
        switch (responseStatus) {
            case ApiResponseStatusCode.Success: // noop
                break;
            default:
                const message = `[${api.endpoint}] Error ${responseStatus}. ${responseMessage}`;
                enqueueSnackbar(message, { variant: 'error' });
                break;
        }
    }

    return result;
};
