import { useParams } from 'react-router-dom';
import { Button, Card, CardContent, Typography, useTheme } from '@mui/material';
import { Error } from '@mui/icons-material';

// api
import { ApiResponseStatusCode } from 'store/slices/api/types';
import PostApi from 'store/slices/api/post';

// helper
import CommonHelper from 'app/helper/common';

// components
import PostComponent from 'components/Post';

export type ViewSharedContentPageParams = {
    contentType: string;
    link: string;
};

export function ViewSharedContentPage() {
    // params
    const { link = '' } = useParams<ViewSharedContentPageParams>();

    // post
    const { isFetching, data } = PostApi.useGetPostByLinkQuery({ postUrl: link }, { skip: !link });
    const post = data?.post;

    // response
    const responseStatus = data?.responseStatus;

    return post != null || isFetching ? (
        <PostComponent post={post} isLoading={isFetching} />
    ) : (
        <ErrorView postLink={link} errorCode={responseStatus} />
    );
}

// ErrorView
type ErrorViewProps = {
    postLink: string;
    errorCode?: number;
};

function ErrorView(props: ErrorViewProps) {
    const { postLink, errorCode } = props;

    // theme
    const { palette } = useTheme();

    // error
    const error = errorCode !== ApiResponseStatusCode.Success;

    const next = () => CommonHelper.goToApp(`s/post/${postLink}`);

    return (
        <>
            <Card style={{ width: '100%', maxWidth: 480, marginBottom: 32 }}>
                <CardContent>
                    {error ? (
                        <>
                            <Typography textAlign="center">
                                <Error color="error" />
                            </Typography>
                            <Typography textAlign="center">Sorry, you are unable to view the post.</Typography>
                            <Typography textAlign="center" style={{ color: palette.neutral.main }}>
                                {(() => {
                                    switch (errorCode) {
                                        case ApiResponseStatusCode.InvalidId:
                                            return 'The share link may already expired or invalid.';
                                        default:
                                            return `Something went wrong, please try again later. [${errorCode}]`;
                                    }
                                })()}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography textAlign="center" fontWeight="bold" marginTop={1} marginBottom={4}>
                                Your privacy is our priority. Don't miss this amazing post shared by your friend!
                            </Typography>
                            <Typography textAlign="center" style={{ color: palette.neutral.main }}>
                                Get Yessly app to view the full post and unlock exclusive features.
                            </Typography>
                        </>
                    )}
                </CardContent>
                {!error && (
                    <CardContent style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button variant="outlined" style={{ textTransform: 'none' }} onClick={next}>
                            Get Yessly
                        </Button>
                        <Button variant="contained" style={{ textTransform: 'none' }} onClick={next}>
                            Open Yessly
                        </Button>
                    </CardContent>
                )}
            </Card>
            {!error && (
                <Typography textAlign="center" style={{ color: palette.primary.contrastText }} fontStyle="italic">
                    Let's revolutionize the way we capture <br />
                    and share precious memories!
                </Typography>
            )}
        </>
    );
}
