import React, { Fragment, useMemo, useState } from 'react';
import { Link, Typography } from '@mui/material';
import twitter from 'twitter-text';

// types
import type { User } from 'types/core';

export type Content = { type: 'hashtag'; hashtag: string } | { type: 'mentioned'; user: User };

export type ContentTextProps = {
    text: string;
    includes?: {
        users?: User[];
    };
    /** maximum number of character to show before expanding. Default is 300. */
    maxCharacters?: number;
    onClick?: (content: Content) => void;
};

function ContentText(props: ContentTextProps) {
    const { text, includes, maxCharacters = 300, onClick } = props;

    // collapse
    const [isExpand, setExpand] = useState(false);
    const toggleExpand = () => setExpand((expand) => !expand);

    // text
    const optimizedText = useMemo(() => {
        if (isExpand) return text;

        let sliceText = text.slice(0, maxCharacters);

        // further trim by newline character
        const newLineIndex = sliceText.indexOf('\n');

        return newLineIndex > 0 ? sliceText.slice(0, newLineIndex) : sliceText;
    }, [text, isExpand, maxCharacters]);
    const isCollapsible = optimizedText.length < text.length || isExpand;

    // includes
    const users = includes?.users;

    // entities
    const entities = useMemo(() => twitter.extractEntitiesWithIndices(optimizedText), [optimizedText]);
    const fragments = useMemo(() => {
        let results: React.ReactNode[] = [];

        if (optimizedText != null) {
            let beginIndex = 0;

            // sort entities
            entities.sort((a, b) => a.indices[0] - b.indices[0]);

            for (var i = 0; i < entities.length; i++) {
                const entity = entities[i];
                results.push(<>{optimizedText.substring(beginIndex, entity.indices[0])}</>);

                // hashtag
                if ('hashtag' in entity) {
                    results.push(
                        <HashtagLinkText
                            entity={entity}
                            text={optimizedText}
                            onClick={(hashtag) => onClick?.({ type: 'hashtag', hashtag })}
                        />,
                    );
                }
                // mentioned
                else if ('screenName' in entity) {
                    results.push(
                        <MentionedLinkText
                            entity={entity}
                            text={optimizedText}
                            users={users}
                            onClick={(user) => onClick?.({ type: 'mentioned', user })}
                        />,
                    );
                }
                // unhandled entity
                else {
                    results.push(<>{optimizedText.substring(entity.indices[0], entity.indices[1])}</>);
                }

                beginIndex = entity.indices[1];
            }

            // ending
            results.push(<>{optimizedText.substring(beginIndex, optimizedText.length)}</>);
        }

        return results;
    }, [optimizedText, entities, users, onClick]);

    return (
        <Typography variant="body1" onClick={isCollapsible ? toggleExpand : undefined}>
            {fragments.map((fragment, i) => (
                <Fragment key={i}>{fragment}</Fragment>
            ))}
            {isCollapsible && (
                <Typography display="inline" color="GrayText">
                    {isExpand ? ' See less' : '... See more'}
                </Typography>
            )}
        </Typography>
    );
}
export default React.memo(ContentText);

// HashtagLinkText

type HashtagLinkTextProps = {
    entity: twitter.HashtagWithIndices;
    text: string;
    onClick?: (hashtag: string) => void;
};

const HashtagLinkText = React.memo((props: HashtagLinkTextProps) => {
    const { entity, text, onClick } = props;

    // entity
    const entityText = text.substring(entity.indices[0], entity.indices[1]);
    const hashtag = entity.hashtag;

    return (
        <Link href="#" variant="inherit" color="highlight" underline="none" onClick={() => onClick?.(hashtag)}>
            {entityText}
        </Link>
    );
});

// MentionedLinkText

type MentionedLinkTextProps = {
    entity: twitter.MentionWithIndices;
    text: string;
    users?: User[];
    onClick?: (user: User) => void;
};

const MentionedLinkText = React.memo((props: MentionedLinkTextProps) => {
    const { entity, text, users, onClick } = props;

    // entity
    const entityText = text.substring(entity.indices[0], entity.indices[1]);
    const username = entity.screenName;

    // user
    const user = users?.find((user) => user.username === username);

    return (
        <>
            {user != null ? (
                <Link href="#" variant="inherit" color="highlight" underline="none" onClick={() => onClick?.(user)}>
                    {entityText}
                </Link>
            ) : (
                entityText
            )}
        </>
    );
});
