import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Typography } from '@mui/material';

// store
import store from 'store';

// helper
import CommonHelper from 'app/helper/common';

// pages
import { LandingPage } from './Landing';
import { ViewSharedContentPage } from './Share/ViewSharedContent';
import { ErrorPage } from './Error';

// assets
import yesslyLogo from 'assets/yessly-light.png';

// styles
import './App.scss';

function App() {
    const goToApp = () => CommonHelper.goToApp();

    return (
        <Provider store={store}>
            <div className="app">
                <header className="appHeader">
                    <img className="cursor-pointer" src={yesslyLogo} alt="yessly" onClick={goToApp} />
                </header>
                <div className="appContent">
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route path="/s/:contentType/:link" element={<ViewSharedContentPage />} />
                            <Route path="*" element={<ErrorPage />} />
                        </Routes>
                    </BrowserRouter>
                </div>
                <footer className="appFooter">
                    <Typography variant="body2" color="white">
                        © {new Date().getFullYear()} Yessly from NexCode
                    </Typography>
                </footer>
            </div>
        </Provider>
    );
}

export default App;
