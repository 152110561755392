import React, { MouseEventHandler, useCallback } from 'react';
import ReactImageGallery, { ReactImageGalleryItem, ReactImageGalleryProps } from 'react-image-gallery';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Fab } from '@mui/material';

// components
import MediaComponent from 'components/Media';

// styles
import 'react-image-gallery/styles/scss/image-gallery.scss';
import './MediaGallery.scss';

// types
export type MediaGalleryItem = ReactImageGalleryItem;

export type MediaGalleryProps = Omit<
    ReactImageGalleryProps,
    'renderLeftNav' | 'renderRightNav' | 'renderFullscreenButton' | 'showPlayButton' | 'renderItem'
>;

function MediaGallery(props: MediaGalleryProps) {
    const { additionalClass, ...rest } = props;

    const renderLeftNav = useCallback((onClick: MouseEventHandler<HTMLElement>, disabled: boolean) => {
        return (
            <Fab size="small" className="nav --left" onClick={onClick} disabled={disabled}>
                <ChevronLeft />
            </Fab>
        );
    }, []);

    const renderRightNav = useCallback((onClick: MouseEventHandler<HTMLElement>, disabled: boolean) => {
        return (
            <Fab size="small" className="nav --right" onClick={onClick} disabled={disabled}>
                <ChevronRight />
            </Fab>
        );
    }, []);

    const renderItem = useCallback((item: ReactImageGalleryItem) => {
        return <MediaComponent media={{ id: '', fileName: '', uri: item.original }} />;
    }, []);

    return (
        <ReactImageGallery
            additionalClass={`${additionalClass} mediaGallery`}
            showFullscreenButton={false}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            renderItem={renderItem}
            showPlayButton={false}
            infinite={false}
            lazyLoad
            {...rest}
        />
    );
}
export default React.memo(MediaGallery);
