import React from 'react';
import { useTheme } from '@mui/material';
import { FormatQuote } from '@mui/icons-material';
import { colord } from 'colord';

// styles
import './QuoteView.scss';

export type QuoteViewProps = React.PropsWithChildren;

function QuoteView(props: QuoteViewProps) {
    const { children } = props;

    const { palette } = useTheme();
    const backgroundColor = colord(palette.background.default)
        [palette.mode === 'dark' ? 'lighten' : 'darken'](0.05)
        .toRgbString();

    return (
        <div className="quoteView" style={{ backgroundColor }}>
            <FormatQuote className="quoteIcon" />
            {children}
            <FormatQuote className="quoteIcon" />
        </div>
    );
}
export default React.memo(QuoteView);
