import mime from 'mime';

// types
import type { Media } from 'types/core';
import { MediaType } from 'types/enums';

const MediaHelper = {
    parseMediaType(type: string) {
        if (type.startsWith('image/')) return MediaType.Image;
        else if (type.startsWith('video/')) return MediaType.Video;
        return MediaType.Unknown;
    },
    /** Get identity `MediaType` of a file, either `video` or `image`. */
    getMediaType(media: Pick<Media, 'uri' | 'type'>) {
        const { uri, type } = media;
        if (uri != null) {
            const mimeType = type ?? mime.getType(uri);
            if (mimeType != null) return this.parseMediaType(mimeType);
        }
        return MediaType.Unknown;
    },
    /**
     * Identify media type in collection. Value `MediaType.Unknown` indicates an empty or mixed media in collection.
     */
    getMediaCollectionType(mediaCollection: Media[]) {
        let mediaType: MediaType = MediaType.Unknown;
        for (let media of mediaCollection) {
            const thisMediaType = this.getMediaType(media);
            if (mediaType === MediaType.Unknown) mediaType = thisMediaType;
            else if (mediaType !== thisMediaType) return MediaType.Unknown;
        }
        return mediaType;
    },
    /**
     * Get display caption by identify media type in collection.
     * For example, `a photo`, `2 videos`, `3 photos / videos`, etc.
     */
    getMediaCollectionCaption(mediaCollection: Media[] | undefined) {
        const count = mediaCollection?.length ?? 0;
        if (mediaCollection != null && count > 0) {
            const mediaType = this.getMediaCollectionType(mediaCollection);
            switch (mediaType) {
                case MediaType.Image:
                    return count > 1 ? `${count} photos` : 'a photo';
                case MediaType.Video:
                    return count > 1 ? `${count} videos` : 'a video';
            }
            return `${count > 1 ? count : 'some'} photos / videos`;
        }
    },
};
export default MediaHelper;
