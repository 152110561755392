// types
import { Audience, Media, Place, User } from 'types/core';
import { AudienceType, UserFollowStatus } from 'types/enums';

// defaults value

export const DefaultAudience: Audience = {
    type: AudienceType.Followers,
} as const;

// fallback value

export const BrokenMedia: Media = {
    id: 'fallback-broken-media',
    fileName: 'broken-media.jpg',
    uri: require('assets/images/broken-media.jpg'),
    type: 'image/jpeg',
} as const;

export const AnonymousUser: User = {
    userId: 'fallback-anonymouse-user',
    username: 'anonymous',
    name: 'Anonymous',
    followStatus: UserFollowStatus.Unknown,
    followerStatus: UserFollowStatus.Unknown,
} as const;

export const UnknownPlace: Place = {
    id: 'fallback-unknown-place',
    name: 'Unknown',
};
