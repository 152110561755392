import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

declare module '@mui/material/styles' {
    interface Palette {
        onPrimary: Palette['primary'];
        neutral: Palette['primary'];
    }

    interface PaletteOptions {
        onPrimary: PaletteOptions['primary'];
        neutral: PaletteOptions['primary'];
    }

    interface TypographyVariants {
        highlight: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        highlight?: React.CSSProperties;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        onPrimary: true;
        neutral: true;
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        onPrimary: true;
        neutral: true;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        highlight: true;
    }
}

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#fc6a73',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#ffc05e',
        },
        error: {
            main: red.A400,
        },
        // custom color
        onPrimary: {
            main: '#ffffff',
            contrastText: '#fc6a73',
        },
        neutral: {
            main: 'rgba(0,0,0,0.54)',
        },
    },
    typography: {
        body1: {
            fontSize: '0.875rem',
        },
        body2: {
            fontSize: '0.75rem',
        },
        highlight: {
            color: '#0078d7',
        },
    },
});

export default theme;
