type CommonApiResponse = {
    responseStatus: number;
    responseMessage: string;
};

/**
 * Generic API Response Type
 *
 * # Usage
 * **Empty Data Type**
 * ```ts
 * const emptyData: ApiResponse = {
 *    ...commonApiResponse,
 * }
 * ```
 *
 * **Simple Data Type**
 * ```ts
 * const simpleData: ApiResponse<'count', number> = {
 *    ...commonApiResponse,
 *    count: 5,
 * }
 * ```
 *
 * **Multiple Data Type**
 * ```ts
 * const multipleData: ApiResponse<{ foo: string; count: number }> = {
 *    ...commonApiResponse,
 *    foo: 'bar',
 *    count: 5,
 * }
 * ```
 */
export type ApiResponse<
    T1 extends string | Record<string, any> = '',
    T2 = T1 extends string ? object : never,
> = CommonApiResponse & (T1 extends string ? { [Key in T1]: T2 | null } : { [P in keyof T1]: T1[P] | null });

/**
 * Types declaration according to API request.
 */
export namespace ApiRequestType {
    export type GetPostByLinkVars = {
        postUrl: string;
    };
}

/**
 * Types declaration according to API Response Data.
 */
export namespace ApiDataType {
    export type User = {
        userId: string;
        userName: string;
        fullName?: string;
        profileUrl?: string;
        /** Status of following the user. (Am I follow the user?) */
        followerStatus: number;
        /** Status of being followed by the user. (Is the user followed me?) */
        followingStatus: number;
        isOnline?: boolean;
        isBlocked?: boolean;
    };

    export type AuthUser = User & {
        memberToken: string;
    };

    export type UserProfile = {
        userName: string;
        fullName: string;
        profileUrl?: string;
        bio: string;
        followerCount: number;
        followerStatus: number;
        followingStatus: number;
        isPrivacy: boolean;
        /** for bio's tagged user. */
        tags?: User[];
    };

    export type ContactUser = {
        userId: string;
        name: string;
        fullName: string;
        imgUrl?: string;
    };

    export type NewContactUser = {
        name: string;
    };

    export type UserAccount = {
        userName: string;
        fullName: string;
        genderId: number;
        phone?: string;
        email?: string;
        dob?: Date;
        isVerification: boolean;
    };

    export type LookupUser = {
        userId: string;
        userName: string;
        fullName: string;
        followerStatus: number;
        followingStatus: number;
        profileUrl?: string;
        phone?: string;
    };

    export type Post = {
        postId: string;
        postMessage: string;
        user: User;
        place: Place | null;
        tags: User[] | null;
        reaction?: Reaction[];
        media?: Media[];
        commentCount: number;
        createdOn: Date;
        checkInDate?: Date;
        isTagged: boolean;
        isLiked: boolean;
        typeId: number;
        privacy: Audience;

        // for journey only
        journeyId?: string;
        journeyName?: string;
        journeyViewType?: number;

        // for moment only
        viewCount?: number;
        likeCount?: number;
    };

    export type Footprint = {
        postId: string;
        postMessage?: string;
        media?: Media[];
        createdOn: Date;
        // place
        osmId?: string;
        displayName?: string;
        lat?: number;
        lon?: number;
    };

    export type Place = {
        osmId: string;
        placeName: string;
        lat: number;
        lon: number;
        fullAddress?: string;
        categoryId?: string;
        category?: string;
        suburb?: string;
        city?: string;
        state?: string;
        country?: string;
        avgRating?: number;
    };

    export type PlaceInfo = {
        lang?: I18nName;
        openingHour?: OpeningHour;
        contact?: string;
        webSiteUrl?: string;
        instagramUrl?: string;
        facebookLink?: string;
    };

    export type PlaceWithInfo = Place & PlaceInfo;

    export type I18nName = { code: string; name: string }[];

    export type Tip = {
        tipId: string;
        message: string;
        media: Media[];
        isAllowed: boolean;
        useful: ReviewFeedback;
        unUseful: ReviewFeedback;
        createdOn: Date;
        creator: User;
    };

    export type Rating = {
        ratingId: string;
        message: string;
        media: Media[];
        isAllowed: boolean;
        isRecommend: boolean;
        star: number;
        useful: ReviewFeedback;
        unUseful: ReviewFeedback;
        createdOn: Date;
        creator: User;
    };

    export type PhotoReview = {
        photoId: string;
        message: string;
        media: Media[];
        isAllowed: boolean;
        useful: ReviewFeedback;
        unUseful: ReviewFeedback;
        createdOn: Date;
        creator: User;
    };

    export type ReviewFeedback = {
        users: User[];
        userCount: number;
    };

    export type PlaceCategory = {
        key: string;
        value: string;
    };

    export type Circle = {
        circleId: string;
        circleName: string;
        count: number;
        threshold: number;
        isManaged: boolean;
    };

    export type CreatedCircle = {
        circleId: string;
    };

    export type Address = {
        city: string;
        suburb: string;
        state: string;
        country: string;
    };

    export type OpeningHour = {
        mon?: OpeningDayHour;
        tue?: OpeningDayHour;
        wed?: OpeningDayHour;
        thu?: OpeningDayHour;
        fri?: OpeningDayHour;
        sat?: OpeningDayHour;
        sun?: OpeningDayHour;
    };

    export type OpeningDayHour = {
        isClosed: boolean;
        isOpen24Hours: boolean;
        hours: OpeningHourRange[];
    };

    export type OpeningHourRange = {
        open: string;
        close: string;
    };

    export type Audience = {
        permissionId: number;
        circles?: AudienceCircle[];
    };

    export type AudienceCircle = {
        circleId: string;
        circleName: string;
    };

    export type Reaction = {
        reactionType: number;
        count: number;
        users: User[];
    };

    export type Comment = User & {
        id: string;
        message: string;
        reaction?: Reaction[];
        replyCount: number;
        createdOn: Date;
        tags?: User[];
    };

    export type Hashtag = {
        hashTag: string;
    };

    export type Notification = {
        id: string;
        message: string;
        notificationTypeId: number;
        createdOn: Date;
        content?: {
            user?: User;
            postId?: Post['postId'];
            commentId?: Comment['id'];
            replyId?: Comment['id'];
            osmId?: Place['osmId'];
            tipId?: Tip['tipId'];
            ratingId?: Rating['ratingId'];
            photoId?: PhotoReview['photoId'];
            journeyId?: Journey['journeyId'];
            groupId?: Chat['groupId'];
            chatId?: Message['chatId'];
        };
        isRead: boolean;
    };

    export type NotificationGroup = {
        groupId: number;
        name: string;
        count: number;
    };

    export type UserSettings = {
        isPrivate: boolean;
    };

    export type NotificationSettings = {
        commentNotification: boolean;
        followNotification: boolean;
        reactionNotifiaction: boolean;
        tagNotification: boolean;
    };

    export type MediaUploadUrl = {
        fileName: string;
        awsUrl: string;
        uploadId: string;
    };

    export type Media = {
        fileName: string;
        fileUrl: string;
        fileExtension: string;
        mime?: string;
    };

    export type Badge = {
        badgeId: string;
        badgeName: string;
        badgeGroupId: string;
        badgeGroupName: string;
        imageUrl: string;
    };

    export type BadgeGroup = {
        badgeGroupId: string;
        badgeGroupName: string;
        imageUrl: string;
        totalBadges: number;
        totalUnlocked: number;
    };

    export type Contribution = {
        name: string;
        point: number;
    };

    export type ContributionLevel = {
        name: string;
        imgUrl: string;
    };

    export type ContributionGroup = {
        name: string;
        levelName: string;
        imgUrl: string;
    };

    export type CreatePostResponseData = {
        postId: string;
        messages?: RewardMessage[];
        isFirstPost: boolean;
        isFirstCheckIn: boolean;
    };

    export type RewardMessage = {
        typeId: number;
        message: string;
        displayName?: string;
        img?: string;
    };

    export type JourneyTemplate = {
        templateId: string;
        name: string;
        detail: string;
        color: string;
        media: Media[];
        hashTags: string[];
    };

    export type Journey = {
        journeyId: string;
        name: string;
        viewType: number;
        media: Media[];
        defaultHashTag?: string[];
        privacy: Audience;
        owner: User;
        collaborators?: User[];
        setting?: JourneySettings;
        report?: JourneyReport;
    };

    export type JourneySettings = {
        reminder: JourneyReminder;
        scheduler?: JourneyScheduler;
    };

    export type JourneyReminder = {
        isOn: boolean;
        /** Reminder time in minutes, valid value from 0 - 1440 (24hours * 60)  */
        reminderValue?: number;
        startDate: Date;
        endDate?: Date;
    };

    export type JourneyScheduler = {
        repeatType: number;
        /** Custom Repeat Type. E.g. `1` (day), `2` (week), etc. Required when `repeatType` is 4 (custom) */
        repeatValue?: number;
        /** Custom Repeat Frequency. E.g `1` day, `2` week, etc. Required when `repeatType` is 4 (custom) */
        repeatValueType?: number;
        /**
         * Custom Repeat Value. Required when `repeatType` is 4 (custom) and `repeatValue` is not 1 (day)`
         *
         * ### Example
         * **Weekly Repeat** use day of week (Monday - Sunday)
         * ```
         * [1,2,3,4,5,6,7]
         * ```
         *
         * **Monthly Repeat** use day of month (day 1, day 2, ...day 31)
         * ```
         * [1,2,3, ..., 30, 31]
         * ```
         *
         * **Yearly Repeat**
         * - use [Month, Day of Month] when `repeatValue` is 4 (year on day)
         * - use [Month, Week Index, Day of week] when `repeatValue` is 5 (year on date)
         *
         * *Month*: 1-12 (January - December)
         *
         * *Week Index*: 1-5 (First - Fifth week); -1 (Final week)
         *
         * *Day of Week*: 1-7 (Monday - Sunday)
         *
         * *Day of Month*: 1-31 (Day 1 - 31); -1 (Last Day)
         *
         * ```
         * [12, -1] // year on day - December / Last Day
         * [2, -1, 8] // year on date - Tuesday / Final week / August
         * ```
         */
        customRepeatValue?: number[];
    };

    export type JourneyReport = {
        totalCheckIn: number;
        highestStreak: number;
        currentStreak: number;
        missedCheckIn: number;
    };

    export type JourneyDateGroup = {
        checkInDate: Date;
        data: Post[];
    };

    export type JourneyPostCollection = {
        data?: Journey;
        post?: Post[];
    };

    export type Moment = {
        id: string;
        media: ApiDataType.Media[];
        owner?: User;
        createdOn: Date;
        viewCount: number;
        likeCount: number;
        isTagged: boolean;
        isLiked: boolean;
        place: Place;
        taggedUsers?: User[];
        audience?: Audience;
        setting?: ApiDataType.MomentSettings;
    };

    export type MomentGroup = {
        owner: User;
        moment: Moment;
        momentIds: string[];
    };

    export type MomentSettings = {
        isKeep: boolean;
    };

    export type Chat = {
        groupId: string;
        isGroup: boolean;
        title: string;
        owner: User;
        users: User[];
        media: ApiDataType.Media[];
        userCount: number;
        isFav: boolean;
        isMute: boolean;
        isLeave: boolean;
        message: Message;
    };

    export type Message = {
        chatId: string;
        message: string;
        media: ApiDataType.Media[];
        owner: User;
        createdOn: Date;
        isRead: boolean;
        isReadOn?: Date;
        isAllRead: boolean;
        reaction?: Reaction[];
        isAdminMessage: boolean;
    };

    export type DeviceSession = {
        deviceTokenId: string;
        deviceName: string;
        os: string;
        ipAddress: string;
        country: string;
        createdDate: Date;
        lastLogin: Date;
        isCurrent: boolean;
        isActive: boolean;
    };

    export type SendEmailResponseData = {
        /** Provider Type ID for how the email was sent. */
        typeId: EmailProvider;
        /**
         * This value varies by different email provider, check below for details.
         *
         * ### Firebase
         * Firebase Login Custom Token, also use this value as verification ID.
         *
         * ### Other Provider
         * Verification ID for email verification.
         * */
        id: string;
    };

    export type CheckEmailStatusResponseData = {
        /** Whether email verification is verified and completed. */
        status: boolean;
        /** Whether email verification is expired, and should request a new one. */
        isExpired: boolean;
    };
}

// enums
export enum ApiResponseStatusCode {
    Success = 200,
    PrivateProfile = 102,
    IncorrectUsernamePassword = 201,
    InvalidUsername = 202,
    IncorrectCurrentPassword = 204,
    InvalidId = 206,
    CircleNameExist = 207,
    ProhibitedWords = 210,
    UserAccountLocked = 211,
    EmptyEmailPassword = 310,
    JourneyMinimumStartDateError = 311,
    ExceedInputLengthLimit = 312,
    DuplicateAccountDeletionRequest = 320,
    InvalidMemberToken = 502,
    SessionExpired = 503,
    EmailNotExist = 504,
}

export enum VerificationType {
    Phone = 1,
    Email,
}

export enum EmailTemplateType {
    Verification = 1,
    Authentication,
    Login,
    ResetPassword,
}

export enum EmailProvider {
    None = 0,
    Firebase = 1,
    SMTP,
}

export enum MediaUploadType {
    MemberProfile = 1,
    Post,
    PlaceRating,
    PlaceTip,
    PlacePhoto,
    Journey,
    Moment,
    Chat = 10,
}

export enum NotificationType {
    Unknown = 0,
    PostTag = 1,
    PostReaction = 2,
    PostCommentReaction = 3,
    PostReplyReaction = 4,
    PostComment = 5,
    PostCommentTag = 6,
    PostReply = 7,
    PlaceTipReaction = 8,
    PlaceRatingReaction = 9,
    FollowRequest = 10,
    FollowUpdates = 11,
    PlaceMediaReaction = 12,
    LoginUpdates = 15,
    KingDethrone = 16,
    LevelUpdateReviewer = 17,
    LevelUpdateFactFinder = 18,
    LevelUpdatePhotographer = 19,
    LevelUpdateExplorer = 20,
    LevelUpdate = 21,
    JourneyCollaboratorLeave = 32,
    JourneyCollaboratorRemoved = 33,
    JourneyReminder = 34,
    JourneyPostAdd = 35,
    JourneyCollaboratorAdded = 36,
    JourneyEnd = 37,
    JourneyMemory = 38,
    ChatNewMessage = 40,
    ChatMessageUpdate = 41,
}

export namespace ApiConstant {
    export const NullUser: ApiDataType.User = {
        userId: 'null-user',
        userName: '',
        followerStatus: 0,
        followingStatus: 0,
    } as const;

    export const NullNotification: ApiDataType.Notification = {
        id: 'null-notification',
        message: '',
        createdOn: new Date(),
        isRead: false,
        notificationTypeId: 0,
    } as const;
}
