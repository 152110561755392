import React from 'react';
import { Button } from '@mui/material';
import { FavoriteBorder } from '@mui/icons-material';

export type LikeButtonProps = {
    count?: number;
    onClick?: () => void;
};

function LikeButton(props: LikeButtonProps) {
    const { count, onClick } = props;

    const iconOnly = count == null || count <= 0;

    const Icon = () => <FavoriteBorder fontSize="small" />;

    return (
        <Button className="likeButton" startIcon={!iconOnly ? <Icon /> : undefined} color="neutral" onClick={onClick}>
            {iconOnly ? <Icon /> : count}
        </Button>
    );
}
export default React.memo(LikeButton);
