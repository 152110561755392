import React from 'react';
import { BrokenImage } from '@mui/icons-material';
import { Typography } from '@mui/material';
import MediaHelper from 'app/helper/media';
import ReactPlayer from 'react-player';

// types
import type { Media } from 'types/core';
import { MediaType } from 'types/enums';

// styles
import './Media.scss';

export type MediaComponentProps = {
    media: Media;
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
};

function MediaComponent(props: MediaComponentProps) {
    const { media, className, style, onClick } = props;

    // media
    const { uri } = media;

    if (uri) {
        const mediaType = MediaHelper.getMediaType(media);

        if (mediaType === MediaType.Video) {
            return <ReactPlayer url={uri} width="100%" height="100%" playing controls />;
        }

        // default load as image
        return <img className={className} src={uri} alt="" style={style} onClick={onClick} />;
    }

    // invalid
    return (
        <div className="media --broken">
            <BrokenImage sx={{ color: '#eee' }} />
            <Typography className="label" variant="body2" textAlign="center" color="#eee">
                Invalid media file
            </Typography>
        </div>
    );
}
export default React.memo(MediaComponent);
