import { createApi } from '@reduxjs/toolkit/query/react';

// store
import { baseQuery } from './utils/baseQuery';

const api = createApi({
    reducerPath: 'mainApi',
    baseQuery,
    endpoints: () => ({}),
});
export default api;
